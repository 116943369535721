<template>
  <div class="rating-filter-input">
    <SearchIcon
      class="rating-filter-input__search"/>
    <input
      class="rating-filter-input__input"
      v-model='value'
      @input="throttledSave"
      @focus="addBg"
      @blur="removeBg"
      ref="input"
      placeholder="Автор">
    <a @click="clearVal">
    <XIcon
      class="rating-filter-input__clear"
      v-if='currentQuery.length > 0'/>
    </a>
  </div>
</template>

<script>
import SearchIcon from '@/img/svg/icon-search-dark.svg';
import XIcon from '@/img/svg/x.svg';
import { debounce } from 'lodash-es';

export default {
  name: 'RatingFilterInput',
  components: {
    SearchIcon,
    XIcon,
  },
  props: {
    currentQuery: {
      required: true,
    },
  },
  methods: {
    changeQuery() {
      this.$emit('changeQuery', this.value);
    },
    clearVal() {
      this.value = '';
      this.removeBg();
      this.$emit('changeQuery', '');
    },
    addBg() {
      this.$refs.input.classList.add('rating-filter-input__input--is-active');
    },
    removeBg() {
      if (!this.value || this.value.length <= 0) {
        this.$refs.input.classList.remove('rating-filter-input__input--is-active');
      }
    },
  },
  data() {
    const value = this.currentQuery && this.currentQuery.length ? this.currentQuery : '';
    return {
      value,
    };
  },
  computed: {
    throttledSave() {
      const DELAY = 1000;
      return debounce(this.changeQuery, DELAY);
    },
  },
};
</script>
<style lang="scss" scoped>
$block: '.rating-filter-input';
#{$block} {
  position: relative;
  &__input {
    width: 100%;
    height: 32px;
    padding: 0 60px;

    color: #808080;
    font-size: 18px;
    font-weight: 400;
    font-family: "Open Sans";
    line-height: 32px;
    border: 1px solid #ffffff;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
    outline: none;
    &--is-active {
      color: #1a1a1a;
      background-color: #ffffff;
    }
  }
  &__clear {
    cursor: pointer;
    right: 20px;
    top: calc(50% - 10px);
    position: absolute;
    &:hover {
      stroke: #d64140;
    }
  }
  &__search {
    left: 20px;
    top: calc(50% - 10px);
    position: absolute;

    fill: #243843;
  }
}
</style>
