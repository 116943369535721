<template>
  <div class="statistic">
    <StatisticFilter
      class="statistic__filter"
      :filterData='data'
      @submitFilter='submitFilter'/>
    <div
      :class="{
        statistic__result: true,
        wrapper: true,
        'statistic__result--unactive': data.queries[0].length === 0
      }">
      <p class='statistic__text' v-if='data.queries[0].length === 0'>Напишите слово или фразу, чтобы увидеть статистику</p>
      <div class='statistic__chart chartdiv' id="chartdiv" v-else></div>
      <Loader class="statistic__loader" v-if='isLoading'/>
    </div>
  </div>
</template>

<script>
import HTTP from '@/api/http-common';
import qs from 'qs';
import StatisticFilter from './filter/filter';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import Loader from '@/components/ui/loader/loader';

export default {
  name: 'Statistic',
  components: {
    StatisticFilter,
    Loader,
  },
  created() {
    this.restoreDataFromQuery(this.$route.query);
    this.getData();
    document.title = 'Крибрум: Статистика фраз';
  },
  data() {
    return {
      data: {
        platform: '',
        queries: [''],
      },
      result: {
        series: [],
        result_code: '',
      },
      isLoading: false,
    };
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.result.rating = [];

      const preparedData = {
        platform: this.data.platform,
      };
      for (let i = 0; i < this.data.queries.length; i++) {
        const key = `query.${i}`;
        preparedData[key] = this.data.queries[i];
      }

      HTTP.post('/trend', qs.stringify(preparedData))
        .then((res) => {
          this.isLoading = false;
          const obData = res.data;
          const code = obData.result_code;
          if (code === 200) {
            this.result = {
              series: obData.series,
              result_code: code,
            };
            this.createChart(obData.series, obData.queries);
          }
        })
        .catch((err) => {
          if (err.message === '0:N<br>1:o<br>2:t<br>3: <br>4:f<br>5:o<br>6:u<br>7:n<br>8:d<br>') {
            this.result = {
              rating: [],
              result_code: 404,
            };
          }
          this.isLoading = false;
        });
    },
    submitFilter(data) {
      const isNeedQuery = data.doQuery;
      delete data.doQuery;
      this.data = data;
      this.$router.push({
        query: data,
      });
      if (!isNeedQuery) {
        return;
      }
      this.getData();
    },
    restoreDataFromQuery(query) {
      const platforms = ['vk.com', 'facebook.com', 'odnoklassniki.ru', 'instagram.com', 'youtube.com', 'twitter.com'];
      if (query.platform && query.platform.length > 0 && platforms.includes(query.platform)) {
        this.data.platform = query.platform;
      }
      if (query.queries && query.queries.length > 0) {
        this.data.queries = Array.isArray(query.queries) ? query.queries : [query.queries];
      }
    },
    createSeries(seriesName, index, chart, fieldName) {
      const colors = ['#5A7380', '#D54140', '#243843'];
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = fieldName;
      series.dataFields.dateX = 'date';
      series.name = seriesName;
      series.strokeWidth = 1;
      series.minBulletDistance = 40;
      series.fill = colors[index];
      series.stroke = colors[index];
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.fillOpacity = 0.85;
      series.tooltip.label.padding(6, 12, 6, 12);

      /** Bullets */
      const obBullet = new am4charts.CircleBullet();
      series.bullets.push(obBullet);

      return series;
    },
    createChart(series, queries) {
      /* Chart code */
      // Themes begin
      // am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      const chart = am4core.create('chartdiv', am4charts.XYChart);

      const data = series.map((item) => {
        item = {
          ...item,
          visits0: item[0],
          visits1: item[1],
          visits2: item[2],
        };
        return item;
      });
      chart.language.locale = am4lang_ru_RU;

      const dateAxis = new am4charts.DateAxis();
      dateAxis.opacity = 1;
      chart.xAxes.push(dateAxis);

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.cursorTooltipEnabled = false;
      chart.data = data;

      // Add scrollbar
      const scrollbarX = new am4charts.XYChartScrollbar();
      for (let i = 0; i < queries.length; i++) {
        const ser1 = this.createSeries(queries[i].query, i, chart, `visits${i}`);
        scrollbarX.series.push(ser1);
      }

      for (let i = 0; i < scrollbarX.series.values.length; i++) {
        scrollbarX.series.values[i].strokeWidth = 2;
      }

      scrollbarX.scrollbarChart.xAxes.values[0].opacity = 0;
      chart.scrollbarX = scrollbarX;
      chart.scrollbarX.tooltip.disabled = true;
      chart.scrollbarX.parent = chart.bottomAxesContainer;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  $block: ".statistic";
  #{$block} {
    justify-self: stretch;
    flex: 1 1 auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    &__filter {
      padding: 16px;
      flex: 0 0 auto;
    }
    &__result {
      width: 100%;
      flex: 1 1 auto;

      position: relative;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      &--unactive {
        justify-content: center;
        align-items: center;
      }
    }
    &__chart {
      width: 100%;
      flex: 1 1 auto;

      min-height: 300px;
      max-width: 1334px;
      margin: 0 auto;
      display: block;
    }
    &__loader {
      top: calc(50% - 18px);
      left: calc(50% - 18px);
      position: absolute;
    }
    &__text {
      padding: 16px;

      color: #808080;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  @media all and (min-width: 960px) {
    #{$block} {
      &__filter {
        padding: 16px 0;
      }
      &__result {
        padding: 16px 180px 0 180px;
      }
      &__text {
        padding: 16px 0;
      }
    }
  }

</style>
