<template>
  <div class="result-pagination">
    <ul class="result-pagination__list">
      <li class="result-pagination__item result-pagination__item--prev" v-if='currentPage !== 1'>
        <a class="result-pagination__link result-pagination__link--prev" @click='$emit("prevPage")'>Предыдущая страница</a>
      </li>

      <li class="result-pagination__item result-pagination__item--next" v-if='currentPage !== pages'>
        <a class="result-pagination__link result-pagination__link--next" @click='$emit("nextPage")'>Следующая страница</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Result-Pagination',
  props: {
    pages: {
      required: true,
    },
    currentPage: {
      required: true,
    },
  },
  created() {

  },
};
</script>
<style lang="scss" scoped>
  $block: '.result-pagination';
  #{$block} {
    &__list {
      padding: 40px 0;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
    &__item {
      list-style-type: none;
      &--prev {
        margin-right: 40px;
      }
      &--next {
        margin-left: 40px;
      }
    }
    &__link {
      width: 40px;
      height: 40px;
      display: block;

      color: #5a7380;
      font-size: 18px;
      font-weight: 700;
      font-family: "Open Sans";
      text-align: center;
      cursor: pointer;
      &--is-active {
        color: #ffffff;
        background-color: #d64140;
      }
      &--prev,
      &--next {
        width: auto;
      }
    }
  }
</style>
