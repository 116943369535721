export const filterPlatforms = [
    {
        name: 'Телеграм',
        icon: 'telegram-color',
        platform: 'telegram.org',
    },
    {
        name: 'ВКонтакте',
        icon: 'vkontakte-color',
        platform: 'vk.com',
    },
    /*
    {
        name: 'Facebook',
        icon: 'facebook-color',
        platform: 'facebook.com',
    },
    {
        name: 'Одноклассники',
        icon: 'odnoklassniki-color',
        platform: 'odnoklassniki.ru',
    },
    */
    {
        name: 'Instagram',
        icon: 'instagram-color',
        platform: 'instagram.com',
    },
    /*
    {
        name: 'Youtube',
        icon: 'youtube-color',
        platform: 'youtube.com',
    },
    */
    {
        name: 'Twitter',
        icon: 'twitter-color',
        platform: 'twitter.com',
    }
]