var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    wall: true,
  }},[_c('Link',{staticClass:"wall__back",attrs:{"text":"Назад к поиску","url":_vm.backUrl,"noDecoration":true,"icon":"arrow-right","iconPosition":"left"},on:{"click":_vm.back}}),_c('div',{class:{
      "wall__list": true,
      "wall__list--is-loading": _vm.isLoading,
    }},[(_vm.mainPost)?_c('div',{staticClass:"wall__item wall__item--main"},[_c('Article',{attrs:{"articleData":_vm.mainPost,"time":_vm.now}})],1):_vm._e(),(_vm.dublicates.length > 0)?_c('div',{staticClass:"wall__buttons"},[_c('a',{staticClass:"wall__counter"},[_vm._v(_vm._s(_vm.foundText))])]):_vm._e(),_vm._l((_vm.dublicates),function(dub){return _c('div',{key:dub.id,staticClass:"wall__item"},[_c('Article',{attrs:{"articleData":dub,"time":_vm.now}})],1)})],2),_c('Loader',{ref:"loader",class:{
      "wall__loader": true,
      "wall__loader--is-loading": _vm.isLoading,
    }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }