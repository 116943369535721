var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    wall: true,
    "wall--not-found": _vm.articles.length <= 0,
  }},[(_vm.articles.length > 0)?_c('div',{staticClass:"wall__buttons"},[_c('a',{staticClass:"wall__counter"},[_vm._v(_vm._s(_vm.foundText))]),_c('div',{staticClass:"wall__found found sortText"},[_vm._v(" Сортировать: "),_c('span',{staticClass:"found__link",on:{"click":_vm.changeSort}},[_vm._v(_vm._s(_vm.sortText))])]),_c('div',{staticClass:"wall__found found"},[_vm._v(" Группировать: "),_c('span',{staticClass:"found__link",on:{"click":_vm.changeDup}},[_vm._v(_vm._s(_vm.dupText))])])]):_vm._e(),(_vm.articles.length > 0)?_c('div',{class:{
      "wall__list": true,
      "wall__list--is-loading": _vm.isLoading,
    }},_vm._l((_vm.articles),function(article){return _c('div',{key:article.id,staticClass:"wall__item"},[_c('Article',{attrs:{"articleData":article,"time":_vm.now}})],1)}),0):_vm._e(),(_vm.isEmptyQuery && _vm.articlesCount === 0)?_c('div',{staticClass:"not-found"},[_c('h3',{staticClass:"not-found__title"},[_vm._v("Введите поисковый запрос")])]):_vm._e(),([400, 404, 429, 500].includes(_vm.resultCode))?_c('div',{staticClass:"not-found"},[_c('h3',{staticClass:"not-found__title",domProps:{"innerHTML":_vm._s(_vm.messages[_vm.resultCode])}})]):_vm._e(),(_vm.articles.length > 0 && _vm.articlesCount !== 0 && _vm.articles.length === _vm.articlesCount && _vm.articlesLimit > _vm.articlesCount)?[_c('div',{staticClass:"not-found"},[(_vm.time !== "" && _vm.time !== "all")?_c('h3',{staticClass:"not-found__title"},[_vm._v(" Показаны все записи"),_c('br'),_vm._v(" Чтобы увидеть больше попробуйте "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeTime.apply(null, arguments)}}},[_vm._v("снять ограничение по времени")])]):(_vm.platform !== "")?_c('h3',{staticClass:"not-found__title"},[_vm._v(" Показаны все записи"),_c('br'),_vm._v(" Чтобы увидеть больше попробуйте "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeSocial.apply(null, arguments)}}},[_vm._v("поискать во всех социальных сетях")])]):(_vm.articlesCount === _vm.articles.length)?_c('h3',{staticClass:"not-found__title load-more__link"},[_vm._v("Показаны все записи")]):_vm._e()])]:_vm._e(),_c('Loader',{ref:"loader",class:{
      "wall__loader": true,
      "wall__loader--is-loading": _vm.isLoading,
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }