<template>
  <span v-if='disabled'
    :class='{
      "link": true,
      "link--disabled": true,
      "link--nodecor": noDecoration
    }'
    @click='$emit("click")'
  >
    <div class='link__wrapper'>
      <Icon
        class='link__icon link__icon--left'
        v-if='showIcon && iconPosition === "left"'
        :icon='icon'/>
      <slot
        class='link__icon link__icon--left'
        name='icon'></slot>
      <span
        :class='{
          "link__text": true,
          "link__text--oneRow": oneRow,
        }'
        v-html='text'></span>
      <Icon
        class='link__icon link__icon--right'
        v-if='showIcon && iconPosition === "right"'
        :icon='icon'/>
    </div>
  </span>
  <router-link v-else-if='isRouterLink'
    :class='{
      "link": true,
      "link--pseudo": pseudo,
      "link--nodecor": noDecoration
    }'
    :to='url'
    :title='title'
    :id='id'
    :tabIndex='tabIndex'
  >
    <div class='link__wrapper' @click='$emit("click")'>
      <Icon
        class='link__icon link__icon--left'
        v-if='showIcon && iconPosition === "left"'
        :icon='icon'/>
      <span
        :class='{
          "link__text": true,
          "link__text--oneRow": oneRow,
        }'
        v-html='text'></span>
      <Icon
        class='link__icon link__icon--right'
        v-if='showIcon && iconPosition === "right"'
        :icon='icon'/>
    </div>
  </router-link>
  <a v-else
    :class='{
      "link": true,
      "link--pseudo": pseudo,
      "link--nodecor": noDecoration
    }'
    :href='url'
    :target='(target !== "_self") ? target : false'
    :download='download'
    :title='title'
    :id='id'
    :name='name'
    :tabIndex='tabIndex'
    @click='$emit("click", $event)'
  >
    <div class='link__wrapper'>
      <Icon
        class='link__icon link__icon--left'
        v-if='showIcon && iconPosition === "left"'
        :icon='icon'/>
      <slot
        class='link__icon link__icon--left'
        name='icon'></slot>
      <span
        :class='{
          "link__text": true,
          "link__text--oneRow": oneRow,
        }'
        v-html='text'></span>
      <Icon
        class='link__icon link__icon--right'
        v-if='showIcon && iconPosition === "right"'
        :icon='icon'/>
    </div>
  </a>
</template>

<script>
import universalProps from '@/mixins/universalProps';
import Icon from '@/components/ui/icon/icon.vue';

/**
 * Компонент ссылки.
 * Ссылка — элемент навигации, который обозначает возможность перехода
 * на другую страницу или вызова нового информационного слоя.
 */
export default {
  name: 'Link',
  mixins: [universalProps],
  props: {
    /**
     * Текст ссылки
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * Адрес ссылки
     */
    url: {
      type: String,
    },
    /**
     * Атрибут target.
     * Управляет тем где необходимо открыть ссылку
     */
    target: {
      type: String,
      default: '_self',
      validator: value => ['_blank', '_self', '_parent', '_top'].indexOf(value) !== -1,
    },
    /**
     * Атрибут download.
     * Указывает браузеру что необходимо начать загрузку документа
     */
    download: {
      type: Boolean,
      default: false,
    },
    /**
     * Управление возможностью взаимодействия с компонентом
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Иконка ссылки
     */
    icon: {
      type: String,
    },
    /**
     * Иконка ссылки
     */
    iconPosition: {
      type: String,
      default: 'right',
      validator: value => ['left', 'right'].indexOf(value) !== -1,
    },
    /**
     * Является ли элемент псевдоссылкой (вызывает popup, dropdown, collapse)
     */
    pseudo: {
      type: Boolean,
      default: false,
    },
    /**
     * В случае если указано true, убирает border-bottom у ссылки
     */
    noDecoration: {
      type: Boolean,
      default: false,
    },
    /**
     * Имя компонента в Dom
     */
    name: {
      type: String,
    },
    /**
     * Свойство дающее возможность использовать vue-router
     */
    isRouterLink: {
      type: Boolean,
      default: false,
    },
    oneRow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
  },
  computed: {
    showIcon() {
      if (!this.icon || !this.icon.length) {
        return false;
      }
      return this.icon;
    },
  },
};
</script>

<style scoped lang="scss">
  .link {
    text-decoration: none;
    cursor: pointer;
    outline: none;
    color: rgba(#5a7380, 0.95);
    font-size: 16px;
    font-weight: 400;
    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }
    &__text {
      border-bottom: 1px solid rgba(#5a7380, 0.6);
      transition: border-color 200ms ease-out;
      word-break: break-word;
      &--oneRow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__icon {
      width: 20px;
      height: 20px;
      stroke: #5a7380;
      stroke-width: 2px;
      transition: transform 0.75s ease-out;
      &--left {
        margin-right: 16px;
        transform: rotate(180deg);
      }
      &--right {
        margin-left: 16px;
      }
    }
    &:hover,
    &:focus {
      color: #D74140;
      .link__text {
        border-color: #D74140;
      }
      .link__icon {
        stroke: #D74140;
        transform: rotate(360deg);
      }
    }
    &--disabled,
    &--disabled:hover {
      color: rgba(#5a7380, 0.6);
      .link__text {
        border-color: rgba(#5a7380, 0.6);
      }
    }
    &--pseudo {
      .link__text {
        border-bottom-style: dashed;
      }
    }
    &--nodecor {
      .link__text {
        border-bottom: 0;
      }
    }
  }
</style>
