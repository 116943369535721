<template>
  <div class="rating-filter-order">
    <div class="rating-filter-order__wrapper" @click="$emit('changeOrder', order)">
      <p
        :class="{
          'rating-filter-order__name': true,
          'rating-filter-order__name--is-active': currentOrder === order
        }">{{name}}</p>
      <SortIcon
        class="rating-filter-order__icon rating-filter-order__icon--is-active"
        v-if="currentOrder === order"/>
        <span class="rating-filter-order__icon" v-else></span>
    </div>
  </div>
</template>

<script>
import SortIcon from '@/img/svg/icon-sort.svg';

export default {
  name: 'RatingFilterInput',
  components: {
    SortIcon,
  },
  props: {
    name: {
      required: true,
    },
    currentOrder: {
      required: true,
    },
    order: {
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
$block: '.rating-filter-order';
#{$block} {
  cursor: pointer;
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__name {
    margin-right: 16px;

    color: #838383;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans";
    transition: color 0.15s ease-in-out;
    &--is-active {
      color: #5a7380;
      font-weight: 700;
    }
  }
  &__icon {
    width: 10px;
    height: 18px;
    fill: #838383;
    transition: fill 0.15s ease-in-out;
    &--is-active {
      fill: #5a7380;
    }
  }
  &:hover {
    #{$block} {
      &__name {
        color: #d64140;
      }
      &__icon {
        fill: #d64140;
      }
    }
  }
}
</style>
