<template>
  <section
    :class='{
      wall: true,
    }'>
      <Link
        class='wall__back'
        text='Назад к поиску'
        :url='backUrl'
        :noDecoration='true'
        icon='arrow-right'
        iconPosition='left'
        @click='back'/>
      <div
      :class='{
        "wall__list": true,
        "wall__list--is-loading": isLoading,
      }'>
        <div class='wall__item wall__item--main' v-if="mainPost">
          <Article :articleData='mainPost' :time='now'/>
        </div>
        <div class='wall__buttons' v-if='dublicates.length > 0'>
          <a class='wall__counter'>{{foundText}}</a>
        </div>
        <div class='wall__item' v-for='dub in dublicates' :key='dub.id'>
          <Article :articleData='dub' :time='now'/>
        </div>
      </div>
      <Loader :class='{
        "wall__loader": true,
        "wall__loader--is-loading": isLoading,
      }' ref='loader'/>
  </section>
</template>
<script>
import Article from '@/components/ui/article/article.vue';
import Loader from '@/components/ui/loader/loader.vue';
import Link from '@/components/ui/link/link.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { declOfNum } from '@/api/utils';

/**
 * Компонент отображающий стену материалов
 */
export default {
  name: 'Wall2',
  created() {
    document.title = 'Крибрум: Поиск по социальным сетям';
    window.addEventListener('scroll', this.handleScroll);
    this.timer = window.setInterval(this.updateTime, 10 * 60 * 1000);

    this.$store.dispatch('userInfo');

  
    let yauid =  this.$route.params.id;
    let cluster_id = this.$route.params.cluster_id;

    const queryObj = {
      id: yauid,
    };
        
    if (cluster_id && cluster_id !== null && cluster_id !== '') {
      queryObj.cluster_id = cluster_id;
    }


    this.$store.dispatch('searchDublicates', queryObj)
      .then(res2 => {
        this.mainPost = res2.post;
        if (res2.duplicates && res2.duplicates.length > 0) {
          this.dublicates = res2.duplicates.filter(item2 => item2.yauid !== this.mainPost.yauid);
          this.total = this.dublicates.length;
        }

        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.clearInterval(this.timer);
  },
  mounted() {
    this.$nextTick(function () {
      this.$refs.loader.$el.style.top = `${this.getTopPositionLoader() + window.scrollY}px`;
    });
  },
  components: {
    Article,
    Loader,
    Link
  },
  computed: {
    ...mapGetters([
      'getCurQueryParam',
    ]),
    ...mapState({
      resultCode: state => state.search.result.code,
      query: state => state.search.curQuery.query,
      author: state => state.search.curQuery.author,
      platform: state => state.search.curQuery.platform,
      time: state => state.search.curQuery.time,
    }),
    backUrl() {
      return this.$store.getters.getPrevPath;
    },
    foundText() {
      let count = this.total ? this.total : 0;
      if (count < 1000) {
        return `${declOfNum(count, ['Найдена', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['похожая запись', 'похожие записи', 'похожих записей'])}`;
      }
      if (count < 1000000) {
        count = Math.round(count / 1000);
        return `${declOfNum(count, ['Найдена', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['тысяча похожих записей', 'тысячи похожих записей', 'тысяч похожих записей'])}`;
      }

      count = Math.round(count / 1000000);
      return `${declOfNum(count, ['Найден', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['миллион похожих записей', 'миллиона похожих записей', 'миллионов похожих записей'])}`;
    },
    sort() {
      return this.$store.getters.getCurQueryParam('order');
    },
    sortText() {
      return this.sort === 'ptime' ? 'сначала новые' : 'сначала старые';
    },
    isEmptyQuery() {
      if (this.query && this.query.length) {
        return false;
      }

      if (this.author && this.author.length && this.platform && this.platform.length) {
        return false;
      }

      return true;
    },
    isSearchPage() {
      return this.$route.path === '/search' || this.$route.name === 'doubles';
    },
  },
  watch: {
    isLoading() {
      this.$refs.loader.$el.style.top = `${this.getTopPositionLoader() + window.scrollY}px`;
    },
  },
  methods: {
    ...mapActions([
      'search',
      'userInfo',
    ]),
    changeSort() {
      const value = this.sort === 'iptime' ? 'ptime' : 'iptime';
      this.$store.commit('UPDATE_PARAM', { field: 'order', value });

      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: this.time,
          order: value,
        },
      });
      this.$store.dispatch('search');
    },
    getTopPositionLoader() {
      const isMobile = window.outerWidth < 960;
      const isSearchPage = this.isSearchPage || false;
      const windowHeight = window.innerHeight;
      const headerSelector = isMobile && isSearchPage ? '.popup--search-js-size' : '.header';
      const headerHeight = document.querySelector(headerSelector).offsetHeight;
      const mainHeight = document.querySelector('.body').offsetHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;

      const isFooterVisible = (headerHeight + mainHeight + footerHeight) === windowHeight;
      if (isFooterVisible) {
        const bodyHeight = windowHeight - (headerHeight + footerHeight);
        const bodyCenter = bodyHeight / 2;
        return bodyCenter - 18;
      }

      const centerBodyAndFooter = (windowHeight - headerHeight) / 2;
      return centerBodyAndFooter - 18; // 18 - половина высоты лоадера
    },
    handleScroll() {
      const windowHeight = window.innerHeight;
      const headerHeight = document.querySelector('.header').offsetHeight;
      const mainHeight = document.querySelector('.body').offsetHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;

      const isFooterVisible = (headerHeight + mainHeight + footerHeight) === windowHeight;
      let visibleHeight = 0;
      if (isFooterVisible) {
        visibleHeight = windowHeight - (headerHeight + footerHeight);
      } else {
        visibleHeight = windowHeight - headerHeight;
      }

      const bodyHeight = document.body.offsetHeight;
      const isDocumentEnd = bodyHeight - (window.scrollY + visibleHeight + headerHeight) <= 50;
      let isDataForRequestExist = false;
      if (this.query && this.query.length > 0) {
        isDataForRequestExist = true;
      }
      if (this.author && this.author.length > 0 && this.platform && this.platform.length > 0) {
        isDataForRequestExist = true;
      }

      if (isDocumentEnd && !this.isLoading && isDataForRequestExist) {
        this.$store.dispatch('search');
      }
    },
    getTime() {
      return Date.now() || new Date().getTime();
    },
    updateTime() {
      this.now = this.getTime();
    },
    removeTime() {
      this.$store.commit('UPDATE_PARAM', { field: 'time', value: 'all' });
      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: 'all',
          order: this.sort,
        },
      });
      this.$store.dispatch('search');
    },
    removeSocial() {
      this.$store.commit('UPDATE_PARAM', { field: 'platform', value: '' });
      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: '',
          time: this.time,
          order: this.sort,
        },
      });
      this.$store.dispatch('search');
    },
    back(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
  },
  data() {
    return {
      now: this.getTime(),
      timer: '',
      messages: {
        400: 'Пустой запрос<br>Пожалуйста измените параметры запроса.',
        404: 'По вашему запросу ничего не найдено<br>Пожалуйста измените поисковый запрос.',
        429: 'Вы превысили максимальное количество запросов<br>Пожалуйста подождите и повторите запрос.',
        500: 'Произошла неизвестная ошибка<br>Пожалуйста попробуйте позже.',
      },
      mainPost: false,
      dublicates: [],
      isLoading: true,
      total: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
  $block: '.wall';
  #{$block} {
    flex: 1 0 100%;
    position: relative;
    &--not-found {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__list {
      position: relative;
      &--is-loading {
        &:before {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 11;
          background: rgba(255, 255, 255, 0.75);
          content: ' ';
        }
      }
    }
    &__item {
      border-bottom: 1px solid rgba(#808080, 0.2);
      &:first-of-type,
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &__loader {
      display: none;
      position: absolute;
      left: calc(50% - 18px);
      z-index: 11;
      &--is-loading {
        display: block;
      }
    }
    &__buttons {
      text-align: center;
    }
    &__counter {
      display: block;

      color: #808080;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  @media all {
    #{$block}{
      margin: 16px 0;
      padding: 0 16px;
      &__buttons {
        margin: 16px 0;
      }
      &__counter {
        margin-bottom: 4px;
      }
    }
  }
  @media all and (min-width: 960px) {
    #{$block}{
      padding: 0 16px;
      &__buttons {
        padding: 0 196px 0 180px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
  } 
  @media all and (min-width: 1366px) {
    #{$block}{
      padding: 0;
    }
  }
  .found {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    &__link {
      color: #5a7380;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .not-found {
    padding: 16px 0 32px 0;
    text-align: center;
    &__title {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
      a {
        color: #5a7380;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
</style>
<style lang="scss">
$block: '.wall';
@media all and (min-width: 960px) {
  #{$block} {
    &__item--main {
      .article__wrapper:before {
        display: block;
        width: calc(100% - 180px + 32px);
        height: calc(100% - 48px);
        position: absolute;
        z-index: -1;
        top: 16px;
        left: calc(180px - 16px);
        background: #f7f7f7;
        content: " ";
      }
    }
  }
}
</style>
<style lang="scss" scoped>
$block: '.wall';
@media all {
  #{$block} {
    &__buttons {
      margin: 0;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(#808080, 0.2);
    }
  }
}
@media all and (min-width: 960px) {
  #{$block} {
    &__back {
      display: block;
      padding: 0 196px 0 180px;
    }
  }
}
</style>