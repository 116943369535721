<template>
  <div class='article'>
    <div
      :class='{
        "article__wrapper": true,
        "article__wrapper--detail": isDetail,
        "article__wrapper--no-margin": isShowParentPost,
      }'>
      <Link
        class='article__back'
        v-if='isDetail'
        text='Назад к поиску'
        :url='backUrl'
        :noDecoration='true'
        icon='arrow-right'
        iconPosition='left'
        @click='back'/>
      <header class='article__header first'>
        <template v-if="isRepost">
          <template v-if='getProperty("author") && getProperty("author").length > 0'>
            <Link
              class='article__author'
              :text='getProperty("author")'
              :url='generateLink'
              :noDecoration='true'
              @click='changeSearch'/>
          </template>
          <template v-else-if="login">
            <Link
              class='article__login'
              :text='login'
              :url='generateLink'
              :noDecoration='true'
              @click='changeSearch'/>
          </template>
          <template v-else>

          </template>
          <span class="article__author">сделал(a) репост</span>
          <div class='article__time time'>
            <div
              class='time__wrapper'
              :data-time='strTime2'>
              <Icon
                class='time__icon'
                icon='clock'/>
              <time
                class='time__time'
                datetime='2018-06-23T11:09:00+03:00'
                v-if='strTime'
                :data-time='strTime2'>{{strTime}}</time>
            </div>
          </div>
        </template>
        <template v-else>
          <Link
            class='article__author'
            v-if='getProperty("author")'
            :text='getProperty("author")'
            :url='generateLink'
            :noDecoration='true'
            @click='changeSearch'/>
          <Link
            class='article__login'
            v-if='login'
            :text='login'
            :url='generateLink'
            :noDecoration='true'
            @click='changeSearch'/>
          <div class='article__time time'>
            <div
              class='time__wrapper'
              :data-time='strTime2'>
              <Icon
                class='time__icon'
                icon='clock'/>
              <time
                class='time__time'
                datetime='2018-06-23T11:09:00+03:00'
                v-if='strTime'
                :data-time='strTime2'>{{strTime}}</time>
            </div>
          </div>
          <span class="article__posttype" v-if='isUserHasComments && getProperty("type")'>{{postType}}</span>
        </template>
      </header>
      <Link
        class='article__title'
        v-if='getProperty("title")'
        :text='getProperty("title")'
        :url='detailLink'
        :noDecoration='true'
        :isRouterLink='true'/>
      <main
        class='article__body'
        v-if='getProperty("body") && isDetail'
        v-html='getProperty("body")'></main>
      <main
        class='article__body'
        v-if='getProperty("snippet") && !isDetail'
        v-html='getProperty("snippet")'></main>
      <img
        class='article__detailImg'
        v-if='isDetail && getProperty("image_ui")'
        :src='getProperty("image_ui")'
        @error='hideImg(".article__detailImg")'>
      <div class='article__videoContainer' v-if='isDetail && getProperty("video_id")'>
        <iframe
          class='article__detailVideo'
          :src='"https://www.youtube.com/embed/" + getProperty("video_id") + ""'
          ></iframe>
      </div>
      <footer
        :class='{
          article__footer: true,
          last: true,
          "article__footer--detail": isDetail,
        }' v-if="!isDetail">
        <Link
          class='article__link article__link--original'
          rel='noopener'
          target='_blank'
          v-if='!isDetail && getProperty("url_ui")'
          :text='getProperty("url_ui")'
          :url='getProperty("url")'
          :noDecoration='true'
          :oneRow='true'>
          <template slot='icon'>
            <Social
              class='article__scl'
              v-if='!isDetail && social'
              :showName='false'
              :code='social.code'
              :name='social.name' />
          </template>
        </Link>
        <Link
          class='article__link article__link--detail'
          v-if='!isDetail && detailLink'
          text='Подробнее'
          :url='detailLink'
          icon='eye'
          iconPosition='right'
          :noDecoration='true'
          :isRouterLink='true'/>
      </footer>
      <ArticleVideo
        class='article__video'
        v-if='!isDetail && getProperty("video_id")'
        :videoId='getProperty("video_id")'/>
      <router-link
        class='article__img'
        :to='detailLink'
        v-if='!isDetail'>
        <template v-if="article.type == 'i'">
          <ArticleImg :img='getProperty("url")' v-if='getProperty("url")' />
        </template>
        <template v-else>
          <ArticleImg :img='getProperty("image_ui")' v-if='getProperty("image_ui")'/>
        </template>
      </router-link>
      <Counter
        class='article__counter'
        v-if='showCounter'
        :nshares='article.nshares'
        :nduplicates='article.nduplicates'
        :ncomments='article.ncomments'
        :nlikes='article.nlikes'
        :nimages='article.nimages'
        :url='dubLink' />
      <Link
          class='article__url'
          rel='noopener'
          target='_blank'
          v-if='isDetail && getProperty("url_ui")'
          :text='getProperty("url_ui")'
          :url='getProperty("url")'
          :noDecoration='true'
          :oneRow='true'>
          <template slot='icon'>
            <Social
              class='article__scl article__scl--detail'
              v-if='isDetail && social'
              :showName='false'
              :code='social.code'
              :name='social.name'></Social>
          </template>
      </Link>
      <!-- Кнопки деталки -->
      <div class='article__links'>
        <Link
          class='article__link article__link--back'
          v-if='isDetail'
          text='Назад к поиску'
          :url='backUrl'
          icon='arrow-right'
          iconPosition='left'
          :noDecoration='true'
          @click='back'/>
      </div>
    </div>
    <template v-if="isDetail">
       <div class="article-tabs" v-if="showCommentTab || showRepostTab">
          <ul class="article-tabs__list">
            <li class="article-tabs__item" v-if="showCommentTab">
              <a
                class="article-tabs__link"
                :class="{'article-tabs__link--is-active': tab == 'comment'}"
                href="#comment"
                @click="changeTab">Комментарии</a>
            </li>
            <li class="article-tabs__item" v-if="showRepostTab">
              <a
                class="article-tabs__link"
                :class="{'article-tabs__link--is-active': tab == 'shares'}"
                href="#shares"
                @click="changeTab">Репосты</a>
            </li>
          </ul>
          <div class="article-tabs__detail">
            <template v-if="tab === 'comment'">
              <ArticleComment
                class='article__comment'
                v-for='comment in getProperty("comments")'
                :key='comment.yauid'
                :commentData='comment'
                />
            </template>
            <template v-else-if="tab === 'shares'">
              <ArticleShare
                class='article__comment'
                v-for='share in joinedShares'
                :key='share.yauid'
                :shareData='share'
                />
            </template>
          </div>
       </div>
    </template>

    <template v-if="isShowParentPost">
      <ArticleParent :articleData="article._parent" />
    </template>

  </div>
</template>

<script>
import 'url-search-params-polyfill';
import { mapGetters } from 'vuex';
import { isExist, detectSocial, declOfNum } from '@/api/utils';
import Social from '@/components/ui/social/social.vue';
import Link from '@/components/ui/link/link.vue';
import ArticleImg from '@/components/ui/articleImg/articleImg.vue';
import ArticleVideo from '@/components/ui/articleVideo/articleVideo.vue';
import ArticleComment from '@/components/ui/articleComment/articleComment.vue';
import ArticleShare from '@/components/ui/articleShare/articleShare.vue';
import ArticleParent from '@/components/ui/articleParent/articleParent.vue';
import Counter from '@/components/ui/counter/counter.vue';
import Icon from '@/components/ui/icon/icon.vue';
/**
 * Компонент отображающий материал
 */
export default {
  name: 'Article',
  props: {
    articleData: {
      type: Object,
    },
    time: {
      type: Number,
    },
  },
  created() {
    if (!this.articleData) {
      window.scrollTo(0, 0);
      this.isDetail = true;
      this.$store.dispatch('searchPost', {
        id: this.$route.params.id,
        query: this.$route.query.query,
      })
        .then((res) => {
          let { type, yauid, cluster_id } = res.post;
          if (type === 's') {
            this.isRepost = true;
          }

          if (res.comments && res.comments.length > 0) {
            this.showCommentTab = true;
            this.tab = 'comment';
          }
          if (res.shares && res.shares.length > 0) {
            let a = res.shares;
            a = a.sort((item1, item2) => item1.ptime - item2.ptime);
            this.joinedShares = a;
            this.showRepostTab = true;
            if (!this.showCommentTab) {
              this.tab = 'shares';
            }
          } 
          

          if (!this.isUserHasComments) {
            return;
          }

          const exist = Object.prototype.hasOwnProperty.call(this.$route.params, cluster_id);
          if (!cluster_id && exist) {
            cluster_id = this.$route.params.cluster_id;
          }

          const queryObj = {
            id: yauid,
          };
          
          if (cluster_id && cluster_id !== null && cluster_id !== '') {
            queryObj.cluster_id = cluster_id;
          }
          
          this.$store.dispatch('searchDublicates', queryObj)
          .then(res2 => {
            if (res2.duplicates && res2.duplicates.length > 0) {
              
              res2.duplicates.map(dub => {
                const isExist = this.joinedShares.filter(share => share.yauid === dub.yauid);
                if (isExist.length == 0) {
                  this.joinedShares = [...this.joinedShares, dub];
                }
              });
              this.joinedShares.sort((item1, item2) => item1.ptime - item2.ptime);
              
              this.showRepostTab = true;
              if (!this.showCommentTab) {
                this.tab = 'shares';
              }
            }
          })
          .catch(() => {

          });
        });
    } else {
      const { type } = this.articleData;
      if (type === 's') {
        this.isRepost = true;
      }
    }
  },
  components: {
    Social,
    Link,
    ArticleImg,
    ArticleVideo,
    ArticleComment,
    ArticleShare,
    ArticleParent,
    Counter,
    Icon,
  },
  methods: {
    changeTab(e) {
      e.preventDefault();

      const target = e.target;
      const href = target.getAttribute('href');
      if (!href) {
        return;
      }

      this.tab = href.replace('#', '');
    },
    getDub() {
      this.tab = 'similar';
    },
    getProperty(fieldName) {
      if (!isExist(this.article, fieldName)) {
        return '';
      }
      return this.article[fieldName];
    },
    changeSearch(e) {
      e.preventDefault();
      e.stopPropagation();
      let { platform, login, author } = this.article;
      const isLoginExist = login && login.length > 0;
      author = isLoginExist ? login : author;

      this.$store.commit('UPDATE_PARAM', { field: 'platform', value: platform });
      this.$store.commit('UPDATE_PARAM', { field: 'query', value: '' });
      this.$store.commit('UPDATE_PARAM', { field: 'author', value: author });
      this.$router.push({
        name: 'search',
        query: {
          query: '',
          author,
          platform,
          time: this.$route.query.time,
          order: this.$route.query.order,
        },
      });
      window.scrollTo(0, 0);
      this.$store.dispatch('search');
    },
    back(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    hideImg(selector) {
      const $removedElement = document.querySelector(selector);
      const $parentElement = $removedElement.parentElement;
      if ($removedElement && $parentElement) {
        $parentElement.removeChild($removedElement);
      }
    },
  },
  computed: {
    ...mapGetters([
      'isUserHasComments',
    ]),
    hasComments() {
      const fieldName = 'comments';
      if (!isExist(this.article, fieldName)) {
        return false;
      }

      return Array.isArray(this.article[fieldName]) && this.article[fieldName].length > 0;
    },
    hasReposts() {
      const fieldName = 'shares';
      if (!isExist(this.article, fieldName)) {
        return false;
      }

      // Если это обычный пользователь
      if (!this.isUserHasComments) {
        return Array.isArray(this.article[fieldName]) && this.article[fieldName].length > 0;
      }

      return false;
    },
    login() {
      const fieldName = 'login';
      if (!isExist(this.article, fieldName)) {
        return '';
      }
      let login = this.article[fieldName];
      if (login.indexOf('@') === -1) {
        login = `@${login}`;
      }

      return login;
    },
    showCounter() {
      const fields = ['nshares', 'nduplicates', 'ncomments', 'nlikes', 'nimages'];
      for (const field of fields) {
        if (isExist(this.article, field)) {
          return true;
        }
      }
      return false;
    },
    social() {
      const fieldName = 'platform';
      if (!isExist(this.article, fieldName)) {
        return false;
      }
      const res = detectSocial(this.article.platform);
      return res.code === 'other' ? {
        name: this.article.platform,
        code: 'explorer',
      } : res;
    },
    detailLink() {
      if (!isExist(this.article, 'yauid')) {
        return false;
      }

      const { yauid, cluster_id } = this.article;

      let url = `/search/${yauid}`;
      if (cluster_id && cluster_id.length > 0) {
        url += `/${cluster_id}`;
      }

      url += `?query=${this.$route.query.query}`;
      return url;
    },
    dubLink() {
      if (!isExist(this.article, 'yauid')) {
        return false;
      }

      const { yauid, cluster_id } = this.article;

      let url = `/search/${yauid}`;
      if (cluster_id && cluster_id.length > 0) {
        url += `/${cluster_id}`;
      }

      url += `/doubles?query=${this.$route.query.query}`;
      return url;
    },
    article() {
      if (this.articleData) {
        return this.articleData;
      }

      const articleID = this.$route.params.id;
      return this.$store.getters.getArticles.filter(article => article.yauid === articleID)[0];
    },
    isShowParentPost() {
      if (this.isDetail) {
        return false;
      }
      if (!isExist(this.article, '_parent')) {
        return false;
      }

      if (['p'].includes(this.article.type)) {
        return false;
      }

      if (this.article.type === 'i') {
        return true;
      }
      if (!this.isUserHasComments) {
        return false;
      }

      return true;
    },
    commentsText() {
      let count = 0;
      if (this.article && this.article.comments && this.article.comments.length) {
        count = this.article.comments.length;
      }
      return `${count} ${declOfNum(count, ['комментарий', 'комментария', 'комментариев'])}`;
    },
    sharesText() {
      let count = 0;
      if (this.article && this.article.shares && this.article.shares.length) {
        count = this.article.shares.length;
      }
      return `${count} ${declOfNum(count, ['репост', 'репоста', 'репостов'])}`;
    },
    dubText() {
      let count = 0;
      if (!isExist(this.article, 'dublicates')) {
        return false;
      }
      count = this.article.dublicates.length;
      return `${count} ${declOfNum(count, ['дубль', 'дубля', 'дублей'])}`;
    },
    order() {
      return this.$store.state.search.curQuery.order || '';
    },
    strTime() {
      const isDublicatesExist = this.getProperty('nduplicates') > 0;
      let field = 'ptime';
      let mess = '';
      if (isDublicatesExist && this.order === 'ptime' && !this.isDetail) {
        field = 'ptime_max';
        mess = 'последняя публикация ';
      }

      let docTime = this.getProperty(field);
      if (!docTime) {
        if (field === 'ptime_max') {
          docTime = this.getProperty('ptime');
          if (!docTime) {
            return false;
          } else {
            mess = '';
          }
        } else {
          return false;
        }
      }

      docTime = parseInt(docTime, 10) * 1000; // to unix
      const docObDate = new Date(docTime);
      const year = docObDate.getFullYear();
      const month = docObDate.getMonth();
      const day = docObDate.getDate() > 9 ? docObDate.getDate() : `0${docObDate.getDate()}`;
      const hour = docObDate.getHours() > 9 ? docObDate.getHours() : `0${docObDate.getHours()}`;
      const minute = docObDate.getMinutes() > 9 ? docObDate.getMinutes() : `0${docObDate.getMinutes()}`;

      const nowTime = this.time;
      const nowObDate = new Date(nowTime);

      const arrMonth = [
        ['января', 'января', 'января'],
        ['февраля', 'февраля', 'февраля'],
        ['марта', 'марта', 'марта'],
        ['апреля', 'апреля', 'апреля'],
        ['мая', 'мая', 'мая'],
        ['июня', 'июня', 'июня'],
        ['июля', 'июля', 'июля'],
        ['августа', 'августа', 'августа'],
        ['сентября', 'сентября', 'сентября'],
        ['октября', 'октября', 'октября'],
        ['ноября', 'ноября', 'ноября'],
        ['декабря', 'декабря', 'декабря'],
      ];

      if (docTime >= nowTime - (6 * 60 * 1000)) {
        return `${mess}только что`;
      }

      if (docTime >= nowTime - (50 * 60 * 1000)) {
        const num = parseInt(((nowTime - docTime) / 60 / 1000) + 0.5, 10);
        return `${mess}${num} ${declOfNum(num, ['минута', 'минуты', 'минут'])} назад`;
      }

      if (docTime >= nowTime - (90 * 60 * 1000)) {
        return `${mess}1 час назад`;
      }

      if (docTime >= nowTime - (150 * 60 * 1000)) {
        return `${mess}2 часа назад`;
      }

      const nowYear = nowObDate.getFullYear();
      const nowMonth = nowObDate.getMonth();
      const nowDate = nowObDate.getDate();
      const midnight = new Date(nowYear, nowMonth, nowDate).getTime();
      if (docTime >= midnight) {
        return `${mess}сегодня в ${hour}:${minute}`;
      }

      const yeasterdayMidnight = midnight - (24 * 60 * 60 * 1000);
      if (docTime >= yeasterdayMidnight) {
        return `${mess}вчера в ${hour}:${minute}`;
      }

      if (year === nowObDate.getFullYear()) {
        return `${mess}${day} ${declOfNum(day, arrMonth[month])} ${hour}:${minute}`;
      }

      return `${mess}${day} ${declOfNum(day, arrMonth[month])} ${year}`;
    },
    strTime2() {
      const isDublicatesExist = this.getProperty('nduplicates') > 0;
      let docTime = this.getProperty('ptime');
      if (!docTime) {
        return false;
      }
      
      docTime = parseInt(docTime, 10) * 1000; // to unix
      const obTime = new Date(docTime);
      const day = obTime.getDate() > 9 ? obTime.getDate() : `0${obTime.getDate()}`;
      const month = obTime.getMonth() + 1;
      const strM = month > 9 ? month : `0${month}`;
      const year = obTime.getFullYear();
      const hour = obTime.getHours() > 9 ? obTime.getHours() : `0${obTime.getHours()}`;
      const minute = obTime.getMinutes() > 9 ? obTime.getMinutes() : `0${obTime.getMinutes()}`;

      if (!isDublicatesExist || this.isDetail) {
        return `${day}.${strM}.${year} ${hour}:${minute}`;
      }

      const numDublicates = this.getProperty('nduplicates') + 1;
      const docTimeMin = this.getProperty('ptime_min');
      const docTimeMax = this.getProperty('ptime_max');
      if (!docTimeMin || !docTimeMax) {
        return false;
      }

      // eslint-disable-next-line func-names
      const formatFullDate = function (ms) {
        const docTime2 = parseInt(ms, 10) * 1000;
        const obTime2 = new Date(docTime2);

        const day2 = obTime2.getDate() > 9 ? obTime2.getDate() : `0${obTime2.getDate()}`;
        const month2 = obTime2.getMonth() + 1;
        const strM2 = month2 > 9 ? month2 : `0${month2}`;
        const year2 = obTime2.getFullYear();
        const hour2 = obTime2.getHours() > 9 ? obTime2.getHours() : `0${obTime2.getHours()}`;
        const minute2 = obTime2.getMinutes() > 9 ? obTime2.getMinutes() : `0${obTime2.getMinutes()}`;
        return `${day2}.${strM2}.${year2} ${hour2}:${minute2}`;
      };

      return `${numDublicates} ${declOfNum(numDublicates, ['сообщение', 'сообщения', 'сообщений'])}: с ${formatFullDate(docTimeMin)} до ${formatFullDate(docTimeMax)}`;
    },
    backUrl() {
      return this.$store.getters.getPrevPath;
    },
    generateLink() {
      const loginProp = this.getProperty('login');
      const authorProp = this.getProperty('author');
      const platformProp = this.getProperty('platform');
      if (!authorProp || !platformProp) {
        return '';
      }

      const isLoginExist = loginProp && loginProp.length > 0;
      const author = isLoginExist ? loginProp : authorProp;

      const obPath = new URL(document.location.href);
      let isPolyfill = false;
      if (!obPath.searchParams) {
        isPolyfill = true;
        obPath.searchParams = new URLSearchParams(obPath.search);
      }
      const authorAction = obPath.searchParams.has('author') ? 'set' : 'append';
      const platformAction = obPath.searchParams.has('platform') ? 'set' : 'append';

      obPath.searchParams[authorAction]('author', author);
      obPath.searchParams[platformAction]('platform', platformProp);
      obPath.searchParams.set('query', '');

      if (isPolyfill) {
        obPath.search = `?${obPath.searchParams.toString()}`;
      }

      return `${obPath.pathname}${obPath.search}`;
    },
    postType() {
      let typeStr;
      const type = this.getProperty('type');
      switch (type) {
        case 'i':
          typeStr = 'Текст с изображения';
          break;
        case 'c':
          typeStr = 'Комментарий';
          break;
        case 's':
          typeStr = 'Репост';
          break;
        case 'p':
        default:
          typeStr = 'Пост';
          break;
      }

      return typeStr;
    },
  },
  data() {
    return {
      isDetail: false,
      tab: 'comment',
      isRepost: false,
      showCommentTab: false,
      showRepostTab: false,
      joinedShares: [],
    };
  },
};
</script>
<style lang='scss' scoped>
$block: '.article';

#{$block} {
  word-wrap: break-word;
  &__wrapper {
    position: relative;
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }
  &__posttype,
  &__author,
  &__login,
  &__time {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
  }
  &__posttype {
    border: 1px solid #e8e8e8;
    padding: 0 8px;
  }
  &__author {
    color: #243843;
  }
  &__time {
    white-space: nowrap;
  }
  &__title {
    display: block;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  &__body {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 400;
  }
  &__url {
    display: block;
  }
  &__scl--detail {
    margin-left: 0 !important;
  }

  /** Элементы деталки */
  &__back {
    display: block;
    margin-bottom: 8px;
  }
  &__detailImg {
    width: 100%;
    height: auto;
    display: block;
    margin: 16px 0;
  }
  &__videoContainer {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  &__detailVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__footer {
    &--detail {
      justify-content: space-between;
    }
  }
  &__comment {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  /** Комменты */
  &__comments {
    border-top: 1px solid rgba(#808080, 0.2);
  }
}

@media all {
  #{$block} {
    &__wrapper {
      padding: 24px 0;
      &--detail {
        padding: 16px;
      }
      &--no-margin {
        padding-bottom: 0 !important;
      }
    }
    &__comments {
      padding: 16px;
    }
    &__author,
    &__login,
    &__time {
      flex: 0 0 100%;
    }
    &__scl {
      margin: 0 8px 0 0;
    }
    &__link {
      display: block;
      margin: 4px 0;
    }


    &__header,
    &__title,
    &__body,
    &__footer,
    &__counter {
      margin: 8px 0;
    }
    &__url {
      margin: 16px 0;
    }

    &__footer {
      margin-bottom: 0;
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }

    &__img,
    &__video {
      display: block;
      margin: 16px 0;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__wrapper {
      padding: 32px 196px 48px 180px;
    }
    &__comments {
      padding: 24px 196px 24px 180px;
    }
    &__header {
      display: flex;
      flex-flow: row wrap;
    }
    &__author,
    &__login,
    &__time {
      margin: 0 8px;
      flex: 0 0 auto;
      white-space: nowrap;
    }

    &__header,
    &__footer {
      & > :first-child {
        margin-left: 0;
      }
      & > :last-child {
        margin-right: 0;
      }
    }
    &__img,
    &__video {
      margin: 0;
      position: absolute;
      top: 24px;
      right: 0;
    }
    &__footer {
      display: flex;
      flex-flow: row nowrap;
    }
    &__links {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      a {
        margin: 0 !important;
      }
    }
    &__link {
      margin: 0 8px;
      &--original {
        max-width: 70%;
      }
      &--back {
        order: -1;
      }
    }
    &__counter {
      position: absolute;
      top: 20px;
      left: 32px;
    }
  }
}

@media all and (min-width: 1344px) {
  #{$block} {
    &__counter {
      left: 0;
    }
  }
}

.comments__text {
  margin-bottom: 16px;
}
.time {
  cursor: pointer;
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:before {
      display: none;
      padding: 5px 20px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 101;
      color: #ffffff;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      white-space: nowrap;
      background: #5a7380;
      border-radius: 10px;
      content: attr(data-time);
    }
    &:hover {
      &:before {
        display: block;
      }
    }
  }
  &__icon {
    margin-right: 8px;
    flex: 0 0 auto;
  }
  &__time {
    flex: 0 0 auto;
  }
}
</style>
<style lang="scss">
  .article__body > * {
    margin: 8px 0;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: #5a7380;
      font-weight: 700;
    }
  }
</style>
<style lang="scss" scope>
$block: ".article-tabs";
#{$block} {
  &__list {
    margin-bottom: -1px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 1px solid #dee2e6;
  }
  &__item {
    margin: 0 0 -1px 0;
    list-style-type: none;
  }
  &__link {
    padding: 8px 16px;
    display: block;

    color: #808080;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: all 0.25s ease-in-out;
    &--is-active,
    &:hover {
      color: #000;
      border-color: #dee2e6 #dee2e6 #fff;
      background-color: #fff;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__list {
      padding: 0 196px 0 180px;
    }
    &__detail {
      padding: 24px 196px 32px 180px;
    }
  }
}
</style>
