/**
 * Универсальные атрибуты компонентов
 * @mixin
 */
const mixin = {
  props: {
    /**
     * Идентификатор элемента в DOM
     */
    id: {
      type: String,
    },
    /**
     * Последовательность перехода между контролами при нажатии на Tab
     */
    tabIndex: {
      type: Number,
    },
    /**
     * Текст всплывающей подсказки
     */
    title: {
      type: String,
    },
    /**
     * Размер компонента.<br>
     * Принимает один из следующих размеров: ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl']
     */
    size: {
      type: String,
      default: 's',
      validator: value => ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl'].indexOf(value) !== -1,
    },
  },
};

export default mixin;
