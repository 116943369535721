<template>
  <div class="rating-filter-social">
      <ul class="rating-filter-social__list">
        <li class="rating-filter-social__item rating-filter-social__description">Соцсеть</li>
        <li class="rating-filter-social__item" v-for="social in socials" :key="social.id">
          <button
            class="rating-filter-social__link"
            type="button"
            @click="$emit('changePlatform', social.platform)"
          >
            <Icon
              :class='{
                "rating-filter-social__icon": true,
                "rating-filter-social__icon--gray": social.platform !== currentPlatform,
                "rating-filter-social__icon--is-active": social.platform === currentPlatform
              }'
              :icon="social.icon"
              @mouseenter.native="activateColor"
              @mouseleave.native="deactivateColor"
            />
          </button>
        </li>
      </ul>
  </div>
</template>

<script>
/**
 * Компонент Социальных сетей
 */
import Icon from '../../icon/icon';
import { filterPlatforms } from '@/data/statisticFilterPlatforms';

export default {
  name: 'RatingFilterSocial',
  components: {
    Icon,
  },
  props: {
    socials: {
      type: Array,
      default: () => filterPlatforms,
    },
    currentPlatform: {
      required: true,
    },
  },
  methods: {
    activateColor(e) {
      const target = e.currentTarget;
      const isActive = target.classList.contains('rating-filter-social__icon--is-active');
      if (isActive) {
        return;
      }
      target.classList.remove('rating-filter-social__icon--gray');
    },
    deactivateColor(e) {
      const target = e.currentTarget;
      const isActive = target.classList.contains('rating-filter-social__icon--is-active');
      if (isActive) {
        return;
      }
      target.classList.add('rating-filter-social__icon--gray');
    },
  },
};
</script>
<style lang="scss" scoped>
$block: '.rating-filter-social';
#{$block} {
  &__list {
    margin: 0;
    padding: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__item {
    margin-right: 10px;
    list-style-type: none;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__link {
    width: 32px;
    height: 32px;
    padding: 5px;
    display: block;
    border: 1px solid #838383;
    border-radius: 7px;
    background-color: transparent;
    cursor: pointer;
  }
  &__icon {
    display: block;
    filter: grayscale(100) opacity(50%);
    transition: all 0.15s ease-in-out;
    &:hover,
    &:focus,
    &--is-active {
      filter: grayscale(0) opacity(100%);
    }
    &--gray {
      fill: #838383;
      color: #838383;
    }
  }
  &__description {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans";
  }
}

@media all {
  #{$block} {
    &__description {
      display: none;
    }
  }
}

@media all and (min-width: 768px) {
  #{$block} {
    &__description {
      display: block;
    }
  }
}

</style>
