<template>
  <div class='img'>
    <div
      class='img__container'
      :style='{
        "background-image": "url(" + imgSrc + ")",
      }'>
    </div>
  </div>
</template>

<script>
/**
 * Компонент отображающий материал
 */
export default {
  name: 'ArticleImg',
  props: {
    img: {
      type: String,
      required: true,
    },
  },
  computed: {
    imgSrc() {
      if (!this.img) {
        return '';
      }
      return this.img;
    },
  },
};
</script>
<style lang='scss' scoped>
$block: '.img';

#{$block} {
  position: relative;
  &__container {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../img/general/image-not-found.jpg');
    content: ' ';
  }
}
@media all {
  #{$block} {
    width: 100%;
    min-height: 100px;
    &__container {
      min-height: 100px;
    }
  }
}
@media all and (min-width: 960px) {
  #{$block} {
    width: 180px;
    height: 100px;
    &:before {
      background-size: cover;
    }
  }
}
</style>
