import { render, staticRenderFns } from "./rating.vue?vue&type=template&id=f19ee412&scoped=true&"
import script from "./rating.vue?vue&type=script&lang=js&"
export * from "./rating.vue?vue&type=script&lang=js&"
import style0 from "./rating.vue?vue&type=style&index=0&id=f19ee412&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f19ee412",
  null
  
)

export default component.exports