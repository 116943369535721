<template>
  <div class="statistic-filter">
    <form class="statistic-filter__wrapper wrapper" @submit="submitFormWithQuery">
      <div class="statistic-filter__queries">
        <div class="statistic-filter__query statistic-filter-query" v-for="(query, index) in queries" :key="index">
          <div class="statistic-filter-query__wrapper">
            <input class="statistic-filter-query__input" v-model="queries[index]" v-on:keyup.enter="submitFormWithQuery">
            <button class="statistic-filter-query__delete" type="button" @click='deleteQuery(index)' :disabled='isDeleteDisabled'></button>
          </div>
        </div>
      </div>
      <div class="statistic-filter__bottom">
        <StatisticFilterSocial
          class="statistic-filter__social"
          :currentPlatform='filterData.platform'
          @changePlatform='changePlatform'
        />
        <button class="statistic-filter__add" @click='addQuery' type='button' v-if="!isAddDisabled"></button>
        <button class="statistic-filter__submit" :disabled='isButtonDisabled' type='submit'>Показать</button>
      </div>
    </form>
  </div>
</template>

<script>
import StatisticFilterSocial from './filter-social';

export default {
  name: 'StatisticFilter',
  components: {
    StatisticFilterSocial,
  },
  props: {
    filterData: {
      required: false,
    },
  },
  data() {
    const data = this.filterData;
    const filterQueries = data.queries;
    const filterPlatform = data.platform;
    const queries = filterQueries && filterQueries.length > 0 ? filterQueries : [];
    const platform = filterPlatform && filterPlatform.length > 0 ? filterPlatform : '';
    return {
      queries,
      platform,
    };
  },
  computed: {
    isButtonDisabled() {
      if (!this.queries || !this.queries.length > 1) {
        return true;
      }
      return this.queries.reduce((prevVal, curVal) => {
        if (curVal && curVal.length > 0) {
          prevVal = false;
        }
        return prevVal;
      }, true);
    },
    isAddDisabled() {
      return this.queries.length >= 3;
    },
    isDeleteDisabled() {
      return this.queries.length === 1;
    },
  },
  methods: {
    addQuery() {
      if (this.queries.length >= 3) {
        return;
      }
      this.queries.push('');
    },
    deleteQuery(index) {
      this.queries = this.queries.filter((val, i) => index !== i);
      this.submitFormWithQuery();
    },
    changePlatform(platform) {
      if (this.filterData.platform == platform) {
        return;
      }

      this.platform = platform;
      this.submitFormWithQuery();
    },
    submitForm() {
      this.$emit('submitFilter', {
        platform: this.platform,
        queries: this.queries,
        doQuery: false,
      });
    },
    submitFormWithQuery(e) {
      if (e) e.preventDefault();

      if (this.isButtonDisabled) {
        return;
      }
      this.$emit('submitFilter', {
        platform: this.platform,
        queries: this.queries,
        doQuery: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  $block: '.statistic-filter';
  #{$block} {
    padding: 16px 0;
    background-color: #e4e4e4;
    &__bottom {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
    &__add {
      flex: 0 0 auto;

      width: 32px;
      height: 32px;
      margin: 0 16px 0 auto;

      position: relative;

      border: 1px solid #243843;
      border-radius: 4px;
      background-color: transparent;
      transition: background 0.15s ease-in-out;
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        content: " ";
        background-color: #243843;
        transition: background-color 0.15s ease-in-out;
      }
      &:before {
        height: 18px;
        width: 1px;

        top: calc(50% - 9px);
        left: 50%;

      }
      &:after {
        width: 18px;
        height: 1px;

        top: 50%;
        left: calc(50% - 9px);
      }
      &:hover {
        border: 1px solid #d54140;
        background-color: #d54140;
        &:before,
        &:after {
          background-color: #ffffff;
        }
      }
      &:disabled {
        opacity: 0.35;
        &:hover {
          border: 1px solid #243843;
          background-color: transparent;
          &:before,
          &:after {
            background-color: #243843;
          }
        }
      }
    }
    &__submit {
      min-width: 120px;
      padding: 5px 10px;

      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Open Sans';
      line-height: 20px;
      text-align: center;
      border-radius: 4px;
      background-color: #d54140;

      cursor: pointer;
      &:disabled {
        opacity: 0.75;
      }
    }
    &__queries {
      margin-bottom: 16px;
    }
    &__query {
      margin: 0 0 10px 0;
      border-left: 8px solid transparent;
      &:nth-of-type(1) {
        border-color: #5a7380;
      }
      &:nth-of-type(2) {
        border-color: #d54140;
      }
      &:nth-of-type(3) {
        border-color: #243843;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__social {
      display: none;
    }
    &-query {
      border-radius: 3px;
      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
      &__input {
        flex: 1 1 auto;
        padding: 4px 30px;

        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Open Sans';
        line-height: 22px;


        border: 1px solid #e3e3e3;
        background-color: #ffffff;
        outline: none;
      }
      &__delete {
        flex: 0 0 auto;

        width: 32px;
        height: 32px;
        padding: 5px;
        margin-left: 20px;

        position: relative;

        border: 1px solid #243843;
        border-radius: 4px;
        transition: all 0.15s ease-in-out;
        background-color: transparent;
        cursor: pointer;

        &:before,
        &:after {
          position: absolute;
          content: " ";
          transform: rotate(45deg);
          background-color: #243843;
          transition: background-color 0.15s ease-in-out;
        }
        &:before {
          height: 18px;
          width: 1px;

          top: calc(50% - 9px);
          left: 50%;

        }
        &:after {
          width: 18px;
          height: 1px;

          top: 50%;
          left: calc(50% - 9px);
        }


        &:hover {
          border: 1px solid #d54140;
          background-color: #d54140;
          &:before,
          &:after {
            background-color: #fff;
          }
        }
        &:disabled {
          opacity: 0.75;
          &:hover {
            border: 1px solid #243843;
            background-color: transparent;
            &:before,
            &:after {
              background-color: #243843;
            }
          }
        }
      }
    }
  }

  @media all and (min-width: 460px) {
    #{$block} {
      &__social {
        display: flex;
      }
    }
  }

  @media all and (min-width: 960px) {
    #{$block} {
      &__wrapper {
        padding: 0 180px;
      }
    }
  }
</style>
