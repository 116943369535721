<template>
  <div class="result-item" @click="toSearch">
    <div class="result-item__base base">
      <div class="base__wrapper">
        <img class="base__img" :src='pathToImage'/>
        <div class="base__column">
          <p class="base__name">{{result.name}}</p>
          <div class="base__type">
            <Icon class="base__icon" :icon="socialsIcon[result.platform]"/> <span>{{name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="result-item__position position">
      <p class="position__current">{{formatNumber2(result.pos)}}</p>
      <p class="position__delta">
        <IconArrow
          class="position__icon icon"
          v-if="result.pos_status && ['up', 'down'].includes(result.pos_status)"
          :class="{ 'icon--down': result.pos_status !== 'up' }"/>
        <template v-if='result.pos_delta'>
          {{formatNumber2(Math.abs(result.pos_delta))}}
        </template>
      </p>
    </div>
    <div class="result-item__rating rating">
      <p class="rating__current">
        <StarIcon class="rating__icon"/>
        {{formatNumber(result.rank)}}
      </p>
    </div>
    <div class="result-item__subscribers subscribers">
      <template v-if='result.followers && result.followers != null'>
        <p class="subscribers__current">
          <SubscribersIcon class="subscribers__icon"/>{{formatNumber(result.followers)}}</p>
        <p class="subscribers__delta">
          <template v-if='result.followers_delta !== 0'>
            <IconArrow class="position__icon icon" :class="{ 'icon--down': result.followers_delta < 0 }"/>
          </template>
          <template v-if='result.followers_delta && result.followers && (result.followers_delta > result.followers / 1000)'>
            {{formatNumber(Math.abs(result.followers_delta))}}
          </template>
        </p>
      </template>
    </div>
  </div>
</template>

<script>

/** Компонент Рейтинга */
import Icon from '../../icon/icon';
import IconArrow from '@/img/svg/icon-arrow2.svg';
import SubscribersIcon from '@/img/svg/icon-group.svg';
import StarIcon from '@/img/svg/icon-star.svg';
import axios from 'axios';

export default {
  name: 'ResultItem',
  components: {
    Icon,
    IconArrow,
    SubscribersIcon,
    StarIcon,
  },
  props: {
    result: {
      required: true,
    },
  },
  created() {
    const path = `/avatar/author?url=${this.result.extid}@${this.result.platform}`;
    this.getImage(path)
      .then(() => {
        this.pathToImage = path;
      })
      .catch(() => {
        const path2 = this.result.avatar !== null && this.result.avatar.length ? this.result.avatar : '';
        if (path2) {
          this.getImage(path2)
            .then(() => {
              this.pathToImage = path2;
            });
        }
      });
  },
  data() {
    let login;
    if (this.result.is_group) {
      login = 'Сообщество';
    } else if (this.result.platform !== 'youtube.com') {
      login = this.result.login;
    }
    return {
      socialsIcon: {
        'vk.com': 'vkontakte-color',
        'youtube.com': 'youtube-color',
        'facebook.com': 'facebook-color',
        'instagram.com': 'instagram-color',
        'twitter.com': 'twitter-color',
        'odnoklassniki.ru': 'odnoklassniki-color',
        'telegram.org': 'telegram-color',
      },
      pathToImage: '/img/svg/default.svg',
      login,
    };
  },
  methods: {
    getImage(path) {
      return axios.get(path);
    },
    formatNumber(number) {
      const parsedNumber = parseInt(number, 10);
      if (isNaN(parsedNumber)) {
        return number;
      }

      if (parsedNumber >= 0 && parsedNumber < 1000) {
        return parsedNumber;
      }

      if (parsedNumber >= 1000 && parsedNumber < 10000) {
        const thousands = parseInt(parsedNumber / 1000);
        const modulo = parsedNumber - thousands * 1000;
        let moduloString = modulo.toString();
        if (moduloString.length < 3) {
          for (let i = moduloString.length; i < 3; i++) {
            moduloString = `0${moduloString}`;
          }
        }
        return `${thousands} ${moduloString}`;
      }

      if (parsedNumber >= 10000 && parsedNumber < 999500) {
        let thousands = Math.round(parsedNumber / 1000) * 1000;
        thousands /= 1000;
        return `${thousands} тыс.`;
      }

      if (parsedNumber >= 999500 && parsedNumber < 9950000) {
        const millions = Math.round(parsedNumber / 1000000);
        const modulo = parsedNumber - millions * 1000000;
        let thousands = 0;
        if (modulo < 0) {
          thousands = 0;
        } else {
          thousands = Math.round(modulo / 100000);
        }
        return `${millions},${thousands} млн.`;
      }

      if (parsedNumber >= 9950000 && parsedNumber < 999500000) {
        const millions = Math.round(parsedNumber / 1000000);
        return `${millions} млн.`;
      }

      if (parsedNumber >= 999500000 && parsedNumber < 9950000000) {
        const billiard = Math.round(parsedNumber / 1000000000);
        const modulo = parsedNumber - billiard * 1000000000;
        let millions = 0;
        if (modulo < 0) {
          millions = 0;
        } else {
          millions = Math.round(modulo / 100000000);
        }
        return `${billiard},${millions} млрд.`;
      }

      const billions = Math.round(parsedNumber / 1000000000);
      return `${billions} млрд.`;
    },
    formatNumber2(number) {
      const parsedNumber = parseInt(number, 10);
      if (isNaN(parsedNumber)) {
        return number;
      }

      const newStr = [];
      const arrString = parsedNumber.toString().split('').reverse();
      for (let i = 1; i < (arrString.length + 1); i++) {
        newStr.push(arrString[i - 1]);
        if (i % 3 == 0) {
          newStr.push(' ');
        }
      }
      return newStr.reverse().join('');
    },
    toSearch() {
      this.$router.push({
        path: '/search',
        query: {
          query: '',
          author: `"${this.result.extid}"`,
          platform: this.result.platform,
          time: 'all',
          order: 'ptime',
        },
      });
    },
  },
  computed: {
    name() {
      const result = this.result;
      const platform = result.platform;
      if (result && result.login && result.login.length) {
        if (['youtube.com'].includes(platform)) {
          return '';
        }

        return result.login.indexOf('@') === -1 ? `@${result.login}` : result.login;
      }

      if (result.is_group) {
        return 'Сообщество';
      }

      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
  @import './base.scss';
  @import './position.scss';
  @import './rating.scss';
  @import './subscribers.scss';

  $block: '.result-item';
  #{$block} {
    padding: 20px 0;
    margin: 0 20px;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
  }

  @media all and (max-width: 959px) {
    #{$block} {
      &__base {
        width: 100%;
      flex: 0 0 100%;
        margin-bottom: 16px;
      }
      &__rating {
        order: 6;

        left: 80px;
        bottom: 50px;
        padding: 0;
        position: absolute;
      }
      &__position {
      width: 60px;
      margin-right: 20px;
    }
    }
  }
  @media all and (min-width: 960px) {
    #{$block} {
      padding: 14px 0;
      margin: 0 180px;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      &__position {
        order: -1;
        width: 80px;
      }
      &__base {
        padding: 0 20px;
        flex: 1 1 auto;
      }
      &__rating {
        width: 145px;
        padding: 0 20px;
      }
      &__subscribers {
        width: 155px;
        padding: 0 0 0 20px;
      }
    }
  }

  .icon--down {
    fill: #91272D;
    transform: rotate(180deg);
  }
</style>
