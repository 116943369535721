<template>
  <div class="rating">
    <RatingFilter
      class="rating__filter"
      @submitFilter='submitFilter'
      :filterData='data'
      :dateUpdate='dateUpdate'/>
    <div class="rating__list">
      <ResultItem
        class="result__item"
        v-for='result in result.rating'
        :key='result.extid'
        :result="result"/>
    </div>
    <div class="not-found" v-if="result.result_code === 404">
      <h3 class="not-found__title">По вашему запросу ничего не найдено<br>Пожалуйста измените поисковый запрос.</h3>
    </div>
    <ResultPagination
      @prevPage='prevPage'
      @nextPage='nextPage'
      :currentPage='data.page'
      :pages='result.total_pages'
      v-if='result.result_code !== 404'/>
    <Loader :class='{
        "rating__loader": true,
        "rating__loader--is-loading": isLoading,
      }' v-if='isLoading'/>
  </div>
</template>

<script>

/** Компонент Рейтинга */
import RatingFilter from './rating-filter/rating-filter';
import ResultItem from './result-item/result-item';
import ResultPagination from './result-pagination/result-pagination';
import Loader from '@/components/ui/loader/loader.vue';
import HTTP from '@/api/http-common';
import qs from 'qs';
import { filterPlatforms } from '@/data/filterPlatforms';

export default {
  name: 'Rating',
  components: {
    RatingFilter,
    ResultItem,
    ResultPagination,
    Loader,
  },
  created() {
    this.restoreDataFromQuery(this.$route.query);
    this.getData();
    document.title = 'Крибрум: Рейтинг авторов';
  },
  data() {
    return {
      data: {
        platform: filterPlatforms[0].platform,
        show: '',
        query: '',
        order: 'rank',
        page: 1,
      },
      dateUpdate: '',
      result: {
        rating: [],
        result_code: '',
        total_pages: 0,
      },
      isLoading: false,
    };
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.result.rating = [];
      HTTP.post('/rating', qs.stringify(this.data))
        .then((res) => {
          this.isLoading = false;
          const obData = res.data;
          const code = obData.result_code;
          this.dateUpdate = parseInt(obData.meta.date, 10) * 1000;
          if (code === 200) {
            this.result = {
              rating: obData.rating,
              result_code: code,
              total_pages: obData.total_pages,
            };
          }
        })
        .catch((err) => {
          if (err.message === '0:N<br>1:o<br>2:t<br>3: <br>4:f<br>5:o<br>6:u<br>7:n<br>8:d<br>') {
            this.result = {
              rating: [],
              result_code: 404,
            };
          }
          this.isLoading = false;
        });
    },
    prevPage() {
      this.data.page -= 1;
      this.$router.push({
        query: this.data,
      });
      this.getData();
    },
    nextPage() {
      this.data.page += 1;
      this.$router.push({
        query: this.data,
      });
      this.getData();
    },
    submitFilter(data) {
      this.data = data;
      this.$router.push({
        query: data,
      });
      this.getData();
    },
    restoreDataFromQuery(query) {
      const validate = {
        platform: ['vk.com', 'facebook.com', 'odnoklassniki.ru', 'instagram.com', 'youtube.com', 'twitter.com'],
        show: ['peoples', 'groups'],
        order: ['rank', 'followers'],
      };

      for (const item in query) {
        if (
          item === 'platform' || item === 'order' && validate[item].indexOf(query[item]) !== -1
        ) {
          this.data[item] = query[item];
          continue;
        }
        if (item === 'show') {
          const validSoc = ['vk.com', 'odnoklassniki.ru'];
          if (
            validSoc.indexOf(query.platform) !== -1 || validSoc.indexOf(this.data.platform) !== -1
            && validate[item].indexOf(query[item]) !== -1
          ) {
            this.data[item] = query[item];
          }
          continue;
        }

        if (item in this.data) {
          this.data[item] = (item === 'page') ? parseInt(query[item]) : query[item];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .icon--down {
    fill: #91272D;
    transform: rotate(180deg);
  }
  .result__item {
    border-bottom: 1px solid #d9d9d9;
    &:last-of-type {
      border-bottom: 0;
    }
  }

  .rating {
    justify-self: stretch;
    flex: 1 1 auto;
    &__loader {
      top: calc(50% - 18px);
      left: calc(50% - 18px);
      position: absolute;

    }
  }

  .not-found {
    padding: 16px 0 32px 0;
    text-align: center;
    &__title {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
    }
  }
</style>
