<template>
  <div class='video'>
    <div
      class='video__container'
      :class='{
        video__container: true,
        "video__container--hide-picture": showVideo,
      }'
      :style='{
        "background-image": "url(" + previewSrc + ")",
      }'>
      <a
        class='video__button'
        v-if='!showVideo'
        @click='playVideo($event)'></a>
      <iframe
        class='video__iframe'
        v-if='showVideo'
        :src='src'
        allow="autoplay; encrypted-media"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
/**
 * Компонент отображающий материал
 */
export default {
  name: 'ArticleVideo',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    previewSrc() {
      if (!this.videoId) {
        return '';
      }
      return `https://img.youtube.com/vi/${this.videoId}/mqdefault.jpg`;
    },
    src() {
      if (!this.videoId) {
        return '';
      }
      return `https://www.youtube.com/embed/${this.videoId}/?autoplay=1`;
    },
  },
  data() {
    return {
      showVideo: false,
    };
  },
  methods: {
    playVideo(e) {
      e.stopPropagation();
      this.showVideo = true;
    },
  },
};
</script>
<style lang='scss' scoped>
$block: '.video';

#{$block} {
  &__container {
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &--hide-picture {
      background: none !important;
    }
  }
  &__button {
    display: block;
    position: absolute;
    background-image: url('/img/svg/icon-play.svg');
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
  }
  &__iframe {
    width: 100%;
    height: 100%;
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    width: 180px;
    height: 100px;
    &__button {
      width: 35px;
      height: 38px;
      top: calc(50% - 19px);
      left: calc(50% - 17px);
    }
  }
}
</style>
