<template>
  <div class='social'>
    <div class='social__wrapper'>
      <Icon :class='{
        "social__logo": true,
        "social__logo--without-margin": showName === false,
      }'
      :icon='code'/>
      <span class='social__name' v-if='showName'>{{name}}</span>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/ui/icon/icon.vue';
/**
 * Компонент отображающий значок соцсети
 */
export default {
  name: 'Social',
  props: {
    code: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
$block: '.social';

#{$block} {
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__name {
    color: #808080;
    font-size: 16px;
    font-weight: $font-weight-regular;
  }
  &__logo {
    width: 20px;
    height: 20px;
  }
}

@media all {
  #{$block} {
    &__logo {
      margin-right: 8px;
      &--without-margin {
        margin-right: 0;
      }
    }
  }
}
</style>
