<template>
  <div class="rating-filter">
    <div class="rating-filter__top">
      <RatingFilterSocial
        class="rating-filter__socials"
        :currentPlatform='filterData.platform'
        @changePlatform='changePlatform'
      />
      <p class="rating-filter__update">{{ showDate }}</p>
    </div>
    <div class="rating-filter__bottom">
      <RatingFilterInput
        class="rating-filter__input"
        :currentQuery='filterData.query'
        @changeQuery='changeQuery'/>
      <RatingFilterOrder
        class="rating-filter__sort"
        name='Рейтинг'
        order='rank'
        :currentOrder='filterData.order'
        @changeOrder='changeOrder'/>
      <RatingFilterOrder
        class="rating-filter__sort2"
        name='Подписчики'
        order='followers'
        :currentOrder='filterData.order'
        @changeOrder='changeOrder'/>
    </div>
  </div>
</template>

<script>
import RatingFilterSocial from './rating-filter-social';
import RatingFilterInput from './rating-filter-input';
import RatingFilterOrder from './rating-filter-order';

export default {
  name: 'RatingFilter',
  props: {
    filterData: {
      required: true,
    },
    dateUpdate: {
      default() {
        return 'Обновлено 2 дня назад';
      },
    },
  },
  components: {
    RatingFilterSocial,
    RatingFilterInput,
    RatingFilterOrder,
  },
  methods: {
    changePlatform(platform) {
      if (platform === this.filterData.platform) {
        return;
      }

      const data = {
        ...this.filterData,
        platform,
        page: 1,
      };
      if (!['vk.com', 'odnoklassniki.ru'].includes(platform)) {
        data.show = '';
      }

      this.$emit('submitFilter', data);
    },
    changeQuery(nextQuery) {
      if (nextQuery === this.filterData.query) {
        return;
      }

      this.$emit('submitFilter', {
        ...this.filterData,
        query: nextQuery,
        page: 1,
      });
    },
    changeOrder(nextOrder) {
      if (nextOrder === this.filterData.order) {
        return;
      }
      this.$emit('submitFilter', {
        ...this.filterData,
        order: nextOrder,
        page: 1,
      });
    },
    changeShow(show) {
      const nextShowValue = show !== this.filterData.show ? show : '';
      this.$emit('submitFilter', {
        ...this.filterData,
        show: nextShowValue,
        page: 1,
      });
    },
  },
  computed: {
    showDate() {
      const dateUpdate = this.dateUpdate;
      const curDate = Date.now();
      const diff = curDate - dateUpdate;
      const days = parseInt(diff / 86400000, 10);
      if (days < 30) {
        const obDateUpdate = new Date(dateUpdate || 0);
        let day = obDateUpdate.getDate();
        if (day < 10) {
          day = '0' + day;
        }

        let month = obDateUpdate.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }

        const year = obDateUpdate.getFullYear();
        return `${day}.${month}.${year}`;
      }

      return 'Более месяца назад';
    },
  },
};
</script>
<style lang="scss" scoped>
  $block: '.rating-filter';
  #{$block} {
    background-color: #e5e5e5;
    &__top {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
    }
    &__update {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
      font-family: "Open Sans";
    }
    &__bottom {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      background-color: #e4e4e4;
    }
  }
  @media all and (max-width: 959px) {
    #{$block} {
      padding: 16px 20px;
      &__update {
        margin-bottom: 16px;

        order: -1;

        text-align: center;
      }
      &__show {
        margin-bottom: 16px;
      }
      &__socials {
        margin-bottom: 16px;
      }
      &__input {
        width: 100%;
        margin-bottom: 16px;
      }
      &__bottom {
        justify-content: center;
      }
      &__sort {
        margin-right: 32px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  @media all and (min-width: 960px) {
    #{$block} {
      background-color: transparent;
      &__top {
        flex-flow: row nowrap;
        padding: 16px 180px;
      }
      &__bottom {
        flex-flow: row nowrap;
        justify-content: flex-start;
        padding: 8px 180px;
      }
      &__input {
        margin-right: 40px;
        flex: 1 1 auto;
      }
      &__sort {
        width: 105px;
        margin-right: 40px;
      }
      &__sort2 {
        width: 135px;
      }
    }
  }
</style>
