<template>
  <div class='comment'>
    <div class='comment__header'>
      <div
        class='comment__author'
        v-if='getProperty("author")'>{{getProperty("author")}}</div>
      <div
        class='comment__login'
        v-if='login'>{{login}}</div>
      <div class='comment__time time'>
        <div
          class='time__wrapper'
          :data-time='strTime2'>
          <Icon
            class='time__icon'
            icon='clock'/>
          <time
            class='time__time'
            datetime='2018-06-23T11:09:00+03:00'
            v-if='strTime'
            :data-time='strTime2'>{{strTime}}</time>
        </div>
      </div>
    </div>
    <main
      class='comment__body'
      v-if='text'
      v-html='text'></main>
    <Link
      class='comment__link comment__link--original'
      rel='noopener'
      target='_blank'
      v-if='getProperty("url_ui")'
      :text='getProperty("url_ui")'
      :url='getProperty("url")'
      :noDecoration='true'
      :oneRow='true'>
      <template slot='icon'>
        <Social
          class='article__scl'
          v-if='social'
          :showName='false'
          :code='social.code'
          :name='social.name'/>
      </template>
    </Link>
  </div>
</template>

<script>
import { isExist, declOfNum } from '@/api/utils';
import Link from '@/components/ui/link/link';
import Icon from '@/components/ui/icon/icon';

/**
 * Компонент отображающий комментарий
 */
export default {
  name: 'Comment',
  props: {
    commentData: {
      type: Object,
    },
  },
  created() {
    this.text = this.commentData.snippet && this.commentData.snippet.length ? this.commentData.snippet : this.commentData.body;
  },
  components: {
    Link,
    Icon,
  },
  methods: {
    getProperty(fieldName) {
      if (!isExist(this.commentData, fieldName)) {
        return '';
      }
      return this.commentData[fieldName];
    },
  },
  computed: {
    login() {
      const fieldName = 'login';
      if (!isExist(this.commentData, fieldName)) {
        return '';
      }
      let login = this.commentData[fieldName];
      if (login.indexOf('@') === -1) {
        login = `@${login}`;
      }

      return login;
    },
    strTime() {
      let docTime = this.getProperty('ptime');
      if (!docTime) {
        return false;
      }
      docTime = parseInt(docTime, 10) * 1000; // to unix
      const docObDate = new Date(docTime);
      const year = docObDate.getFullYear();
      const month = docObDate.getMonth();
      const day = docObDate.getDate() > 9 ? docObDate.getDate() : `0${docObDate.getDate()}`;
      const hour = docObDate.getHours() > 9 ? docObDate.getHours() : `0${docObDate.getHours()}`;
      const minute = docObDate.getMinutes() > 9 ? docObDate.getMinutes() : `0${docObDate.getMinutes()}`;

      const nowTime = this.time;
      const nowObDate = new Date(nowTime);

      const arrMonth = [
        ['января', 'января', 'января'],
        ['февраля', 'февраля', 'февраля'],
        ['марта', 'марта', 'марта'],
        ['апреля', 'апреля', 'апреля'],
        ['мая', 'мая', 'мая'],
        ['июня', 'июня', 'июня'],
        ['июля', 'июля', 'июля'],
        ['августа', 'августа', 'августа'],
        ['сентября', 'сентября', 'сентября'],
        ['октября', 'октября', 'октября'],
        ['ноября', 'ноября', 'ноября'],
        ['декабря', 'декабря', 'декабря'],
      ];


      if (docTime >= nowTime - (6 * 60 * 1000)) {
        return 'только что';
      }

      if (docTime >= nowTime - (50 * 60 * 1000)) {
        const num = parseInt(((nowTime - docTime) / 60 / 1000) + 0.5, 10);
        return `${num} ${declOfNum(num, ['минута', 'минуты', 'минут'])} назад`;
      }

      if (docTime >= nowTime - (90 * 60 * 1000)) {
        return '1 час назад';
      }

      if (docTime >= nowTime - (150 * 60 * 1000)) {
        return '2 часа назад';
      }

      const nowYear = nowObDate.getFullYear();
      const nowMonth = nowObDate.getMonth();
      const nowDate = nowObDate.getDate();
      const midnight = new Date(nowYear, nowMonth, nowDate).getTime();
      if (docTime >= midnight) {
        return `сегодня в ${hour}:${minute}`;
      }

      const yeasterdayMidnight = midnight - (24 * 60 * 60 * 1000);
      if (docTime >= yeasterdayMidnight) {
        return `вчера в ${hour}:${minute}`;
      }

      if (year === nowObDate.getFullYear()) {
        return `${day} ${declOfNum(day, arrMonth[month])} ${hour}:${minute}`;
      }

      return `${day} ${declOfNum(day, arrMonth[month])} ${year}`;
    },
    strTime2() {
      let docTime = this.getProperty('ptime');
      if (!docTime) {
        return false;
      }
      docTime = parseInt(docTime, 10) * 1000; // to unix
      const obTime = new Date(docTime);
      const day = obTime.getDate() > 9 ? obTime.getDate() : `0${obTime.getDate()}`;
      const month = obTime.getMonth() + 1;
      const strM = month > 9 ? month : `0${month}`;
      const year = obTime.getFullYear();
      const hour = obTime.getHours() > 9 ? obTime.getHours() : `0${obTime.getHours()}`;
      const minute = obTime.getMinutes() > 9 ? obTime.getMinutes() : `0${obTime.getMinutes()}`;
      return `${day}.${strM}.${year} ${hour}:${minute}`;
    },
  },
  data() {
    return {
      text: "",
    };
  }
};
</script>
<style lang='scss' scoped>
$block: '.comment';

#{$block} {
  &__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    & :first-child {
      margin-left: 0;
    }
    & :last-child {
      margin-right: 0;
    }
  }
  &__author,
  &__login,
  &__time {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
  }
  &__author {
    color: #243843;
  }
  &__body {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 400;
  }
  &__link {
    display: block;
    margin: 8px 0 0 0;
  }
}

@media all {
  #{$block} {
    &__header {
      margin: 0 0 8px 0;
    }
    &__body {
      margin: 8px 0 0px 0;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__header {
      flex-flow: row nowrap;
    }
    &__author,
    &__login,
    &__time {
      margin: 0 16px;
    }
  }
}


.time {
  cursor: pointer;
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:before {
      display: none;
      padding: 5px 20px;
      position: absolute;
      left: calc(100% + 5px);
      top: 0;
      color: #ffffff;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      white-space: nowrap;
      background: #5a7380;
      border-radius: 10px;
      content: attr(data-time);
    }
    &:hover {
      &:before {
        display: block;
      }
    }
  }
  &__icon {
    margin-right: 8px;
    flex: 0 0 auto;
  }
  &__time {
    flex: 0 0 auto;
  }
}
</style>
<style lang='scss'>
.comment__body {
  a {
    color: #5a7380f2;
    &:hover {
      color: #D74140;
    }
  }
}
</style>
