<template>
  <div class='counter'>
    <ul class='counter__list'>
      <li
        class='counter__item'
        v-for='item in filteredItems'
        :key='item.code'
        :data-hint='item.title'
      >
        <template v-if="item.code != 'duplicates'">
          <Icon class='counter__icon' width="20px" height="20px" :icon='item.code'/>
          <span class="counter__number js-counter">{{getProperty(item.code)}}</span>
        </template>
        <template v-else>
          <template v-if="isUserHasComments">
            <router-link :to="url" class="counter__link">
              <Icon class='counter__icon' width="20px" height="20px" :icon='item.code'/>
              <span class="counter__number js-counter">{{getProperty(item.code)}}</span>
            </router-link>
          </template>
          <template v-else>
            <Icon class='counter__icon' width="20px" height="20px" :icon='item.code'/>
            <span class="counter__number js-counter">{{getProperty(item.code)}}</span>
          </template>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isExist } from '@/api/utils';
import Icon from '@/components/ui/icon/icon.vue';
/**
 * Компонент отображающий материал
 */
export default {
  name: 'Counter',
  props: {
    nshares: {
      type: [String, Number],
      default: 0,
    },
    nduplicates: {
      type: [String, Number],
      default: 0,
    },
    ncomments: {
      type: [String, Number],
      default: 0,
    },
    nlikes: {
      type: [String, Number],
      default: 0,
    },
    nimages: {
      type: [String, Number],
      default: 0,
    },
    url: {
      type: String,
      default: '',
    },
  },
  components: {
    Icon,
  },
  computed: {
    ...mapGetters([
      'isUserHasComments',
    ]),
    filteredItems() {
      return this.items.filter((item) => this.getProperty(item.code))
    }
  },
  methods: {
    getProperty(code) {
      const prop = `n${code}`;
      if (!isExist(this, prop)) {
        return '';
      }
      return parseInt(this[prop], 10);
    },
  },
  data() {
    return {
      items: [{
        title: 'Количество репостов',
        code: 'shares',
      }, {
        title: 'Количество похожих сообщений',
        code: 'duplicates',
      }, {
        title: 'Количество комментариев',
        code: 'comments',
      }, {
        title: 'Количество лайков',
        code: 'likes',
      }, {
        title: 'Количество картинок',
        code: 'images',
      }],
    };
  },
};
</script>
<style lang='scss' scoped>
$block: '.counter';

#{$block} {
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    cursor: pointer;
    &:before {
      display: none;
      padding: 5px 20px;
      position: absolute;
      left: 100%;
      top: 100%;
      color: #ffffff;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      white-space: nowrap;
      background: #5a7380;
      border-radius: 10px;
      content: attr(data-hint);
    }
    &:hover {
      &:before {
        display: block;
      }
    }
  }
  &__icon {
    flex: 0 0 20px;
  }
  &__number {
    flex: 0 0 auto;
    color: #808080;
    font-size: 16px;
    font-weight: 400;
  }
  &__link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    color: #808080;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media all {
  #{$block} {
    &__list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    &__item {
      margin: 0 16px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__number {
      margin-left: 8px;
    }
  }
}
@media all and (min-width: 960px) {
    #{$block} {
      &__list {
        display: block;
      }
      &__item {
        margin: 8px 0;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &__number {
        margin-left: 16px;
      }
    }
}
</style>
