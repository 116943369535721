var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disabled)?_c('span',{class:{
    "link": true,
    "link--disabled": true,
    "link--nodecor": _vm.noDecoration
  },on:{"click":function($event){return _vm.$emit("click")}}},[_c('div',{staticClass:"link__wrapper"},[(_vm.showIcon && _vm.iconPosition === "left")?_c('Icon',{staticClass:"link__icon link__icon--left",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._t("icon"),_c('span',{class:{
        "link__text": true,
        "link__text--oneRow": _vm.oneRow,
      },domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.showIcon && _vm.iconPosition === "right")?_c('Icon',{staticClass:"link__icon link__icon--right",attrs:{"icon":_vm.icon}}):_vm._e()],2)]):(_vm.isRouterLink)?_c('router-link',{class:{
    "link": true,
    "link--pseudo": _vm.pseudo,
    "link--nodecor": _vm.noDecoration
  },attrs:{"to":_vm.url,"title":_vm.title,"id":_vm.id,"tabIndex":_vm.tabIndex}},[_c('div',{staticClass:"link__wrapper",on:{"click":function($event){return _vm.$emit("click")}}},[(_vm.showIcon && _vm.iconPosition === "left")?_c('Icon',{staticClass:"link__icon link__icon--left",attrs:{"icon":_vm.icon}}):_vm._e(),_c('span',{class:{
        "link__text": true,
        "link__text--oneRow": _vm.oneRow,
      },domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.showIcon && _vm.iconPosition === "right")?_c('Icon',{staticClass:"link__icon link__icon--right",attrs:{"icon":_vm.icon}}):_vm._e()],1)]):_c('a',{class:{
    "link": true,
    "link--pseudo": _vm.pseudo,
    "link--nodecor": _vm.noDecoration
  },attrs:{"href":_vm.url,"target":(_vm.target !== "_self") ? _vm.target : false,"download":_vm.download,"title":_vm.title,"id":_vm.id,"name":_vm.name,"tabIndex":_vm.tabIndex},on:{"click":function($event){return _vm.$emit("click", $event)}}},[_c('div',{staticClass:"link__wrapper"},[(_vm.showIcon && _vm.iconPosition === "left")?_c('Icon',{staticClass:"link__icon link__icon--left",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._t("icon"),_c('span',{class:{
        "link__text": true,
        "link__text--oneRow": _vm.oneRow,
      },domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.showIcon && _vm.iconPosition === "right")?_c('Icon',{staticClass:"link__icon link__icon--right",attrs:{"icon":_vm.icon}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }